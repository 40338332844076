import * as React from 'react';
import defaultTheme from '@adecco/base-app/src/constants/themes/defaultTheme/defaultTheme';

type Props = {
  className?: string;
};

const Icon404: React.FC<Props> = ({ className }) => (
  <svg
    width="48"
    height="48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 48 48"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.44 3.51h19.31c1.34 0 2.43 1.1 2.43 2.43v35.53c0 1.34-1.1 2.43-2.43 2.43H10.58a2.44 2.44 0 0 1-2.43-2.43v-28.7c0-1.34.48-2.48 1.43-3.43l4.44-4.39a4.62 4.62 0 0 1 3.42-1.44Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.39 13.58h-4.2a.8.8 0 0 1-.81-.81.8.8 0 0 1 .81-.81h4.2c.68 0 1.24-.54 1.24-1.19V4.39a3.9 3.9 0 0 0-2.05 1.12L10.14 9.9c-.8.8-1.19 1.73-1.19 2.86v28.71c0 .89.73 1.62 1.62 1.62h26.18c.89 0 1.62-.73 1.62-1.62V5.95c0-.9-.73-1.62-1.62-1.62h-18.5v6.43a2.84 2.84 0 0 1-2.86 2.82Zm-1.94-9.21a5.47 5.47 0 0 1 4.12-1.66h19.19A3.23 3.23 0 0 1 40 5.95v35.53a3.25 3.25 0 0 1-3.24 3.24H10.58a3.24 3.24 0 0 1-3.24-3.24V12.77c0-1.55.56-2.9 1.67-4.01l4.44-4.39Zm15.43 17.62a5.24 5.24 0 0 1-2.1 4.18c-.27.17-.58.32-.91.47-.96.44-1.35.69-1.35 1.33a.8.8 0 0 1-.81.81.8.8 0 0 1-.81-.81c0-1.74 1.38-2.38 2.29-2.8l.15-.07.47-.24.03-.02a3.62 3.62 0 0 0 1.41-2.85 3.53 3.53 0 0 0-3.53-3.53 3.52 3.52 0 0 0-3.58 3.53.8.8 0 0 1-.81.81.8.8 0 0 1-.81-.81 5.13 5.13 0 0 1 5.2-5.16 5.17 5.17 0 0 1 5.16 5.16ZM22.9 32.02c0 .45.36.81.81.81a.8.8 0 0 0 .81-.81v-.86a.8.8 0 0 0-.81-.81.8.8 0 0 0-.81.81v.86Z"
      fill={defaultTheme.colors.primary}
    />
  </svg>
);

export default Icon404;
